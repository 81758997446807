import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import CasePreview from 'elements/Case/CasePreview'

// Third Party
import styled from 'styled-components'

const StyledCases = styled.div``

const Cases = ({ className }) => {
  const {
    cases: { nodes: cases },
  } = useStaticQuery(graphql`
    {
      cases: allWpKlantcase {
        nodes {
          ...KlantCasePreviewFrag
        }
      }
    }
  `)

  return (
    <StyledCases className={`container ${className ? `${className}` : ``}`}>
      {cases.map((c, index) => (
        <CasePreview c={c} index={index} className="pt-4 pb-5" />
      ))}
    </StyledCases>
  )
}

export default Cases
