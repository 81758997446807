import React, { useState, useLayoutEffect } from 'react'
import Plaatjie from '@ubo/plaatjie'

// Image
import quote from 'img/icon/quote.svg'
import arrowRight from 'img/icon/arrow_right.svg'

// Components
import ButtonMinimal from 'components/elements/ButtonMinimal'

// Third Party
import styled, { css } from 'styled-components'
import parse from 'html-react-parser'
import { motion } from 'framer-motion'
import { useInView } from 'react-hook-inview'

const StyledCasePreview = styled(motion.div)`
  @media screen and (max-width: 991px) {
    padding: 0 15px 0 15px;
  }
`

const StyledImg = styled(Plaatjie)`
  position: absolute !important;
  height: 280px;
  width: 420px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;

  ${(props) =>
    props.even
      ? css`
          right: -280px;
        `
      : css`
          left: -280px;
        `}

  @media screen and (max-width: 991px) {
    position: relative !important;
    left: unset;
    right: unset;
    width: 100%;
    margin-top: 30px;
  }
`

const StyledButtonMinimal = styled(ButtonMinimal)`
  a {
    color: ${(props) => props.theme.color.text.secondary};
    font-weight: ${(props) => props.theme.font.weight.l};
    font-size: ${(props) => props.theme.font.size.sm};

    &:hover {
      color: ${(props) => props.theme.color.text.secondary};
    }
  }
`

const StyledArrowRight = styled.img`
  transform: rotate(180deg);
`

const CaseContent = styled.div`
  background-color: ${(props) => props.theme.color.face.light};
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);

  position: relative;

  ${(props) =>
    props.even
      ? css`
          left: 50px;
          padding: 40px 200px 50px 70px;

          @media screen and (max-width: 991px) {
            left: 0;
            padding: 20px 20px 20px 20px;
          }
        `
      : css`
          right: 50px;
          padding: 40px 70px 50px 200px;

          @media screen and (max-width: 991px) {
            right: 0;
            padding: 20px 20px 20px 20px;
          }
        `}

  h2 {
    font-size: ${(props) => props.theme.font.size.lm};
    font-weight: ${(props) => props.theme.font.weight.xl};
  }

  p {
    font-size: ${(props) => props.theme.font.size.sm};
    line-height: 26px;
  }
`

const CasePreview = ({ c, className, index }) => {
  const [ref, isVisible] = useInView({
    threshold: 0.1,
  })

  const [view, setView] = useState(false)

  useLayoutEffect(() => {
    if (isVisible && !view) {
      setView(true)
    }
  }, [isVisible])

  return (
    <StyledCasePreview
      ref={ref}
      className={`row ${className ? `${className}` : ``}`}
      initial={
        index % 2 === 0 ? { x: 100, opacity: 0.6 } : { x: -100, opacity: 0.6 }
      }
      animate={
        isVisible || view
          ? { x: 0, opacity: 1 }
          : index % 2 === 0
          ? { x: 100, opacity: 0.6 }
          : { x: -100, opacity: 0.6 }
      }
      transition={{ delay: 0.3, duration: 1, type: 'spring', damping: 20 }}
    >
      {index % 2 !== 0 && <div className="col-lg-4" />}
      <CaseContent even={index % 2 === 0} className="col-lg-8">
        <img className="mb-3" src={quote} alt="" />
        <div>{parse(c.postKlantcases.klantcasepreview.description)}</div>
        <motion.div
          whileHover="hover"
          className="d-flex justify-content-lg-start justify-content-center"
        >
          <StyledButtonMinimal to={c.uri}>
            Lees hele klantcase
            <motion.div
              variants={{
                hover: {
                  x: [0, -5, -10, 10, 5, 0],
                  transition: {
                    loop: Infinity,
                    duration: 1,
                  },
                },
              }}
            >
              <StyledArrowRight className="ms-3" src={arrowRight} alt="" />
            </motion.div>
          </StyledButtonMinimal>
        </motion.div>
        {/* <StyledImg even={index % 2 === 0} loading="eager" fadeIn={false} fluid={c.postKlantcases.preview.image.localFile.childImageSharp.fluid} alt="" /> */}
        <StyledImg
          even={index % 2 === 0}
          loading="eager"
          fadeIn={false}
          image={c.postKlantcases.klantcasepreview.image}
          alt=""
        />
      </CaseContent>
    </StyledCasePreview>
  )
}

export default CasePreview
