/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Elements
import HeroDefault from 'elements/Hero/HeroDefault'
import Breadcrumb from 'elements/Breadcrumb'
import Cases from 'elements/Case/Cases'
import ExtraContent from 'elements/ExtraContent'

// Components
import SEO from 'shared/SEO'
import Layout from 'layout/Layout'

const PageTemplate = ({
  data: {
    page: { title, paginaKlantcases, seo },
  },
  pageContext,
}) => {
  return (
    <Layout>
      <SEO seo={seo} />

      <HeroDefault fields={paginaKlantcases.paginaklantcasesbanner} />

      <Breadcrumb data={title} className="py-2" pageContext={pageContext} />

      <Cases className="py-lg-5 py-3" />

      <ExtraContent content={paginaKlantcases.paginaklantcasesrelation} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wpPage(databaseId: { eq: $wordpress_id }) {
      ...KlantCasesFrag
    }
  }
`

export default PageTemplate
